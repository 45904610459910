import { request } from './request'
import qs from 'qs'


/***
 * 
 * --------------------旅游产品--------------------
 */


// 待审核
export function getTourismProductToAudit (params) {
  return request({
    url: '/supplier/tour-product/reservesList',
    method: 'GET',
    params
  })
}

//正式运营
export function getTourismProductToOperation (params) {
  return request({
    url: '/supplier/tour-product/reservesList',
    method: 'GET',
    params
  })
}

// 新增 、编辑 旅游线路
export function metTourismProductEdit (method, datas){
  return request({
    url:'/supplier/tour-product',
    method,
    data: qs.stringify(datas)
  })
}

//旅游线路 详细
export function getTourismProductShow (params){
  return request({
    url:'/supplier/tour-product/show',
    method:'GET',
    params
  })
}

//保存-推荐理由
export function setTourismProductRecommend (datas){
  return request({
    url:'/supplier/tour-product/recommend',
    method: 'POST',
    data: qs.stringify(datas)
  })
}

//保存-费用说明
export function setTourismProductExpense (datas){
  return request({
    url:'/supplier/tour-product/expenseExplanation',
    method: 'POST',
    data: qs.stringify(datas)
  })
}

//保存-产品特色
export function setTourismProductFeature (datas){
  return request({
    url:'/supplier/tour-product/featureDescription',
    method: 'POST',
    data: qs.stringify(datas)
  })
}

//保存-订购须知
export function setTourismProductBuyNotice (datas){
  return request({
    url:'/supplier/tour-product/buyNotice',
    method: 'POST',
    data: qs.stringify(datas)
  })
}

//旅游线路-审核
export function getTourismProductToCheck (datas){
  return request({
    url:'/supplier/tour-product/check',
    method: 'POST',
    data: qs.stringify(datas)
  })
}

//旅游线路-变更上/下架状态
export function ChangeTourismProductStatus (params){
  return request({
    url:'/supplier/tour-product/change-status',
    method: 'PUT',
    params
  })
}

//旅游线路-删除
export function DeleteTourismProduct (params){
  return request({
    url:'/supplier/tour-product',
    method: 'DELETE',
    params
  })
}

//旅游线路 日程列表
export function getTourismProductSchedule (params){
  return request({
    url:'/supplier/tour-product/schedule',
    method: 'GET',
    params
  })
}

//添加 、编辑旅游行程
export function metTourismProductSchedule (method, datas){
  return request({
    url:'/supplier/tour-product/schedule',
    method,
    data: qs.stringify(datas)
  })
}

//详情 旅游行程
export function getTourismProductScheduleShow (params){
  return request({
    url:'/supplier/tour-product/schedule-show',
    method: 'GET',
    params
  })
}

//状态变更 旅游行程
export function ChangeTourismProductScheduleStatus (params){
  return request({
    url:'/supplier/tour-product/schedule/change-status',
    method: 'PUT',
    params
  })
}

//删除 旅游行程
export function DeleteTourismProductSchedule (params){
  return request({
    url:'/supplier/tour-product/schedule',
    method: 'DELETE',
    params
  })
}

//旅游线路 获取日期价格
export function getTourismProductSchedulePrice (params){
  return request({
    url:'/supplier/tour-product/price',
    method: 'GET',
    params
  })
}

//添加 日期价格
export function setTourismProductSchedulePrice (datas){
  return request({
    url:'/supplier/tour-product/price',
    method: 'POST',
    data: qs.stringify(datas)
  })
}

/***
 * 
 * --------------------酒店民宿--------------------
 */
// 待审核
export function getHotelProductToAudit (params) {
  return request({
    url: '/supplier/hotel',
    method: 'GET',
    params
  })
}



// 新增 、编辑 酒店民宿
export function getHotelProductEdit (method, datas){
  return request({
    url:'/supplier/hotel',
    method,
    data: qs.stringify(datas)
  })
}

//获取 详细酒店民宿
export function getHotelProductShow (params){
  return request({
    url:'/supplier/hotel/show',
    method: 'GET',
    params
  })
}

//删除 酒店民宿
export function deleteHotelProduct (method,params){
  return request({
    url:'/supplier/hotel',
    method,
    params
  })
}

//审核酒店、民宿
export function AuditHotelProduct (datas){
  return request({
    url:'/supplier/hotel/check',
    method:'POST',
    data: qs.stringify(datas)
  })
}

//保存-民宿/酒店介绍
export function setHotelProductdescription (datas){
  return request({
    url:'/supplier/hotel/description',
    method:'POST',
    data: qs.stringify(datas)
  })
}

//保存-订购须知
export function setHotelProductbuyNotice(datas){
  return request({
    url:'/supplier/hotel/buyNotice',
    method:'POST',
    data: qs.stringify(datas)
  })
}
//保存-酒店政策
export function setHotelProductoPlicyInfo(datas){
  return request({
    url:'/supplier/hotel/policyInfo',
    method:'POST',
    data: qs.stringify(datas)
  })
}

//酒店/民宿-变更上/下架状态
export function changeHotelProductStatue(params){
  return request({
    url:'/supplier/hotel/change-status',
    method:'PUT',
    params
  })
}

//酒店/民宿 房型列表
export function getHotelProductRoom(params){
  return request({
    url:'/supplier/hotel/room',
    method:'GET',
    params
  })
}

//添加 房型
export function addHotelProductRoom(datas){
  return request({
    url:'/supplier/hotel/room',
    method:'POST',
    data: qs.stringify(datas)
  })
}
//编辑 房型
export function setHotelProductRoom(datas){
  return request({
    url:'/supplier/hotel/room',
    method:'PUT',
    data: qs.stringify(datas)
  })
}
//详情 房型
export function getHotelProductRoomShow(params){
  return request({
    url:'/supplier/hotel/room-show',
    method:'GET',
    params
  })
}

//状态变更 房型
export function changeHotelProductRoomStatus(params){
  return request({
    url:'/supplier/hotel/room/change-status',
    method:'PUT',
    params
  })
}

//房型 日期价格
export function getHotelProductRoomPrice(params){
  return request({
    url:'/supplier/hotel/price',
    method:'GET',
    params
  })
}

//添加 房型日期价格
export function addHotelProductRoomPrice(datas){
  return request({
    url:'/supplier/hotel/price',
    method:'POST',
    data:qs.stringify(datas)
  })
}
//删除 房型
export function delHotelProductRoom(method,datas){
  return request({
    url:'/supplier/hotel/room',
    method,
    data:qs.stringify(datas)
  })
}

/***
 * 
 * --------------------特权产品--------------------
 */

// 特权产品 列表数据
export function getPrivilegeGoodsList (params) {
  return request({
    url: '/supplier/privilege-goods',
    method: 'GET',
    params
  })
}

// 特权产品 编辑列表数据
export function metPrivilegeGoodsList (params) {
  return request({
    url: '/supplier/privilege-goods',
    method: 'PUT',
    params
  })
}

// 特权产品  详情数据
export function getPrivilegeGoodsListDetail (params) {
  return request({
    url: '/supplier/privilege-goods/show',
    method: 'GET',
    params
  })
}

// 特权产品--规格数据
export function getPrivilegeGoodsListNorms (params) {
  return request({
    url: '/supplier/privilege-spec',
    method: 'GET',
    params
  })
}

// 特权产品--同步规格数据
export function synchroPrivilegeGoods (params) {
  return request({
    url: '/supplier/privilege-spec/sys-data',
    method: 'GET',
    params
  })
}


